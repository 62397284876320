import React from 'react';

export default function Resume() {
  return (
    <div className="pt-3 text-center">
      <a className="" download="John-Daniel-Yates" href="\assets\images\Danny Yates Resume.pdf">
       <img src="https://i.imgur.com/Qi1t4zn.png" title="source: imgur.com" className='img-fluid img-thumbnail'></img></a>
    </div>
  );
}
